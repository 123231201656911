<template>
  <!-- prettier-ignore -->
  <div
    ref="contractRef"
    class="contract"
  >
    <the-section :theme="$props.theme === 'light' ? 'dark' : 'light'">
      <Form.Location
        ref="zipChooserRef"
        v-model="$props.formData.address"
        class="contract__location"
        name="location"
        replace
        required
        :autofocus="!$props.formData.address.displayName && !isWww"
        :contract-start-date="basketStore.basket.contractStartDate"
        :disabled="basketStore.basket.existingCustomer"
        :hint="$t('location.placeholder')"
        :theme="$props.theme"
        :title-label="$t('person.plz')"
        :validate="$props.v.address"
      />
    </the-section>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'

import basketStore from '@/store/basket'
import useApplication from '@/hooks/useApplication'

import * as Form from '@/components/Form'

// HOOKS
const { isWww } = useApplication()

// INIT
defineEmits(['update:address'])
defineProps({
  formData: {
    type: Object,
    required: true,
  },
  theme: {
    type: String,
    default: 'light',
  },
  v: {
    type: Object,
    required: true,
  },
})

// DATA
const contractRef = ref(null)

// METHODS
function checkRouteParams() {
  if (isWww.value) return

  const route = useRoute()
  const { fieldName } = route.params
  let target = contractRef.value
  try {
    if (fieldName) {
      const field = contractRef.value.querySelector(`[name=${fieldName}]`)
      target = field.closest('.section')

      field.focus()
      scrollToElement(target)
    }
  } catch {
    // report error to Instana
    if (window.ineum) {
      window.ineum('reportError', `Error at focusing "${target}" for field "${fieldName}" on screen person`)
    }
  }
}

function scrollToElement(element) {
  if (!element?.scrollIntoView) {
    throw new Error()
  }

  // Wait until the animations are over
  setTimeout(() => {
    element.scrollIntoView({
      behavior: 'smooth',
      alignToTop: true,
    })
  }, 500)
}

// LIFECYCLE HOOKS
onMounted(() => {
  checkRouteParams()
})
</script>

<style name="mobile" scoped>
.theme--light {
  background-color: purple;
}

.theme--dark {
  background-color: var(--c-primary-neutral-3);
}
</style>

<style name="desktop" scoped>
@media (--md) {
  .contract__location {
    width: 250px;
  }
}
</style>
