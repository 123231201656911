import { minLength, maxLength, required } from '@vuelidate/validators'

import { contractStartDate } from '@/validations/components'
import { ValidatorsUtil } from '@/utils/Validators'

export default {
  address: {
    required,
    location: ValidatorsUtil.isLocation,
  },
  contractNumber: {
    /*
    required: requiredIf(function () {
      // where is this coming from?
      console.warn('TODO isCollectiveContract')
      return false // this.isCollectiveContract
    }),
    contractNumber: function (value) {
      return ValidatorsUtil.isContractNumber(value)
    },
    */
  },
  contractStartDate: {
    ...contractStartDate,
  },
  persons: {
    required,
    minLength: minLength(1),
    maxLength: maxLength(7),
    duplicatedPersons: ValidatorsUtil.hasNoDuplicatedPersons,
  },
  salesPartnerEmail: {
    email: ValidatorsUtil.isMail,
  },
}
