<template>
  <!-- prettier-ignore -->
  <Form.Form
    ref="formRef"
    class="form"
    name="persons"
    tracking-disabled
    :validator="v"
    @cancel="$emit('cancel', formData)"
    @submit="submit"
  >
    <template v-if="isLoginEnabled">
      <login-action
        ref="loginRef"
        :channel="$props.channel"
        :product-id="$props.productId"
      />
    </template>
    <template v-else>
      <!-- mind the gap :D -->
      <div />
    </template>

    <persons-partner
      v-if="partnerStore.isPrivateInsurer.value"
      v-model="formData"
      :theme="$props.theme"
      :v="v"
    />
    <person-collective
      v-if="partnerStore.isCollective.value"
      :form-data="formData"
      :theme="$props.theme"
    />
    <template v-if="isNewCustomerBroker">
      <the-typography
        class="form__section-title__advisor"
        tag="div"
        type="heading04"
        v-text="$t('screen.welcome.sectionAdvisor')"
      />
      <partner-advisory />
      <the-typography
        class="form__section-title__insured"
        tag="div"
        type="heading04"
        v-text="$t('screen.welcome.sectionInsured')"
      />
    </template>
    <persons-contract
      :form-data="formData"
      :theme="$props.theme"
      :v="v"
    />
    <template
      v-for="(person, index) in displayPersons.mutable"
      :key="person.personId"
    >
      <persons-person
        v-model:person="displayPersons.mutable[index]"
        :contract-start-date="basketStore.basket.contractStartDate"
        :index="getIndex(person.personId)"
        :is-existing-customer="basketStore.basket.existingCustomer"
        :person-groups="personGroups"
        :theme="$props.theme"
        @delete="removePerson(person.personId)"
      />
    </template>
    <transition name="the-transition">
     <the-message
        v-if="v.persons.duplicatedPersons.$invalid"
        severity="error"
        :text="$t('form.error.duplicatedPersons')"
      />
    </transition>
    <div
      class="form-actions"
      :class="{ 'form-actions--single': !allowMultiplePersons }"
    >
      <Form.Button
        v-if="allowMultiplePersons"
        plain
        :disabled="formData.persons.length === MAX_PERSONS"
        :text="$t('person.addPerson')"
        @click="addPerson"
      />
      <Form.Button
        primary
        type="submit"
        :text="(v.$anyDirty) ? $t('form.apply') : $t('form.next')"
      />
    </div>
    <div
      v-if="displayPersons.immutable.length > 0"
      class="form__contract"
    >
      <div class="contract__toggle">
        <Form.Toggle
          v-model="showImmutablePersons"
          data-track-as="toggleExistingPersons"
          name="contractPersons"
          placement="left"
          :data-track-text="!showImmutablePersons ? 'show' : 'hide'"
        >
          <the-typography
            tag="span"
            type="bodyLargeLong"
            v-text="$t('screen.welcome.showContract')"
          />
        </Form.Toggle>
      </div>
      <transition
        mode="out-in"
        :name="transition"
        @after-enter="afterEnter"
        @enter="enter"
        @leave="leave"
      >
        <div
          v-if="showImmutablePersons"
          class="form__immutable-persons"
        >
          <template
            v-for="(person, index) in displayPersons.immutable"
            :key="person.personId"
          >
            <persons-person
              v-model:person="displayPersons.immutable[index]"
              :contract-start-date="basketStore.basket.contractStartDate"
              :index="index"
              :is-existing-customer="basketStore.basket.existingCustomer"
              :person-groups="personGroups"
              :theme="$props.theme"
              @delete="removePerson(person.personId)"
            />
          </template>
        </div>
      </transition>
    </div>
  </Form.Form>
</template>

<script setup>
import { computed, onBeforeMount, onMounted, reactive, ref, unref, watch } from 'vue'

import cloneDeep from 'lodash/cloneDeep'
import useVuelidate from '@vuelidate/core'
import validations from '@/validations/entry'

import { events$ } from '@/services'

import useHeightTransition from '@/directives/HeightTransition'
import { confirm } from '@/utils/Modal'
import { ReactivityUtil } from '@/utils/Reactivity'

import basketStore from '@/store/basket'
import partnerStore from '@/store/partner'
import useI18n from '@/hooks/useI18n'
import usePerson from '@/hooks/usePerson'
import usePersonDetails from '@/hooks/usePersonDetails'
import useStatus from '@/hooks/useStatus'

import * as Form from '@/components/Form'
import PartnerAdvisory from '@/components/Person/PartnerAdvisory'
import PersonCollective from '@/components/Person/Collective'
import PersonsContract from '@/components/Person/Contract'
import PersonsPartner from '@/components/Person/Partner'
import PersonsPerson from '@/components/Person/Person'
import { LoginAction } from '@/components/Login'

import { CHANNEL, LOGIN_OPEN_MODAL, MAX_PERSONS } from '@/config/constants'
import { EVENT_PERSONS_FORM_DISPLAYED } from '@/config/events'

// HOOKS
const { enter, afterEnter, leave } = useHeightTransition()
const { t } = useI18n()
const { addOrderToPersons, createPerson } = usePerson()
const { getPersonsByMutability } = usePersonDetails()
const { isConfirmationNeeded } = useStatus()

// INIT
const emit = defineEmits(['cancel', 'submit'])
const props = defineProps({
  channel: {
    type: String,
    required: true,
  },
  loginDisabled: {
    type: Boolean,
    default: false,
  },
  productId: {
    type: String,
    default: null,
  },
  source: {
    type: String,
    required: true,
  },
  theme: {
    type: String,
    default: 'light',
  },
})

// DATA
const formData = reactive({
  address: {},
  contractStartDate: null,
  contractNumber: null,
  persons: [],
  salesPartnerEmail: null,
})
const formRef = ref(null)
const loginRef = ref(null)
const showImmutablePersons = ref(false)
const transition = ref('slide-down')

// COMPUTED
const allowMultiplePersons = computed(() => {
  // if there are existing customers selected to edit do not allow to add further persons
  return !displayPersons.value.mutable.some(p => p.partnerNumber)
})

const displayPersons = computed(() => {
  return getPersonsByMutability(formData.persons)
})

const isLoginEnabled = computed(() => {
  // Login can be disabled/hidden via prop
  if (props.loginDisabled === true) return false

  return (
    (basketStore.basket.channel !== CHANNEL.ALVA &&
      !partnerStore.isPrivateInsurer.value &&
      !partnerStore.isBroker.value &&
      !partnerStore.isCollective.value &&
      !basketStore.basket.existingCustomer) ||
    !!loginRef.value?.isLoginModalOpen
  )
})

const isNewCustomerBroker = computed(() => {
  return !basketStore.basket.existingCustomer && partnerStore.isBroker.value
})

const personGroups = computed(() => {
  if (formData.contractNumber) {
    return partnerStore.partner.collectiveContractConfig?.personGroups
  }
  return []
})

// METHODS
async function addPerson() {
  const __person = await createPerson()

  formData.persons.push(__person)
}

function getIndex(personId) {
  return formData.persons.findIndex(p => p.personId === personId)
}

function removePerson(personId) {
  formData.persons = formData.persons.filter(p => p.personId !== personId)
  /*
    displayPersons is a computed, that is updated on formData.persons changes, so we get the
    updated mutable persons after removing the person from formData.persons
   */
  const personsLeft = displayPersons.value.mutable
  if (personsLeft.length === 0) {
    addPerson()
  }
  unref(v).$touch()
}

async function submit() {
  // @NOTE: If this is a new customer, we don't care about the contractModified flag
  if (basketStore.basket.existingCustomer === true && !formData.persons.some(p => p.contractModified)) {
    addPerson()
    return
  }

  let payload = Object.assign({}, basketStore.basket, cloneDeep(formData))

  payload.persons = addOrderToPersons(payload.persons)

  if (isConfirmationNeeded(payload, basketStore.basket)) {
    // TODO: naturally, we need to respect the outcome of this async confirm-promise... Rejects in case of cancel
    if (await confirm({ label: t('person.confirmChange') })) {
      emit('submit', payload)
    }
    // don't save in case of cancel
  } else {
    emit('submit', payload)
  }
}

function updateFormData() {
  const { address, collective, contractStartDate, persons, salesPartnerEmail } = basketStore.basket

  ReactivityUtil.reAssign(formData.address, address || {})
  ReactivityUtil.reAssign(formData.persons, persons || [])

  formData.contractStartDate = ReactivityUtil.clone(contractStartDate)
  formData.contractNumber = ReactivityUtil.clone(collective?.contractNumber)

  if (salesPartnerEmail) {
    formData.salesPartnerEmail = salesPartnerEmail
  }

  if (persons?.length === 0) {
    addPerson()
  }
}

// VALIDATION
const v = useVuelidate(validations, formData, { $lazy: true })

// WATCHERS
watch(
  () => basketStore.basket.persons,
  () => {
    updateFormData()
  }
)

// LIFECYCLE HOOKS
onBeforeMount(() => {
  updateFormData()
})

onMounted(() => {
  // if login-stuff is not present, fire the normal persons-funnel-event
  if (!localStorage.getItem(LOGIN_OPEN_MODAL)) {
    events$.emit(EVENT_PERSONS_FORM_DISPLAYED, { source: props.source })
  }
})

// EPILOGUE
defineExpose({ validator: v, formRef })
</script>

<style name="animations" scoped>
.slide-down-enter-active,
.slide-down-leave-active,
.slide-up-enter-active,
.slide-up-leave-active {
  transition-duration: 0.5s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-down-enter,
.slide-up-leave-active,
.slide-down-leave-active,
.slide-up-enter {
  opacity: 0;
  height: 0;
  transform: translate(0, -2em);
}
</style>

<style name="mobile" scoped>
.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .form__section-title__advisor {
    margin: 20px 0 20px;
  }

  .form__section-title__insured {
    margin: 0;
  }
}

.form-actions {
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  .button.plain {
    color: var(--c-primary-color-3);
  }
}

.form__contract {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.form__immutable-persons {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
}

.contract__toggle {
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  margin-top: 16px;
}
</style>

<style name="desktop" scoped>
@media (--md) {
  .form {
    .form__section-title__advisor {
      margin: 30px 0 20px;
    }
  }

  .form-actions {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 8px;
  }

  .form-actions--single {
    justify-content: flex-end;
  }

  .contract__toggle {
    margin-top: 8px;
  }
}
</style>
