<template>
  <!-- prettier-ignore -->
  <div class="prompt">
    <the-typography
      class="prompt__text"
      tag="span"
      type="bodyLargeLong"
      v-text="$t('login.prompt.text')"
    />
    <a
      class="prompt__title"
      data-track-as="login_start"
      :data-track-text="$props.channel"
      @click="openLoginModal(LOGIN_STEP.TYPE_SELECTION)"
    >
      <the-icon
        v-if="$props.showIcon"
        class="prompt__icon"
        art="solid"
        name="arrow-right-long"
      />
      {{ $t('login.prompt.title') }}
    </a>
  </div>
</template>

<script setup>
import { events$ } from '@/services'

import { EVENT_LOGIN } from '@/config/events'
import { LOGIN_STEP } from '@/config/constants'

// INIT
const props = defineProps({
  channel: {
    type: String,
    required: true,
  },
  hideNotification: {
    type: Boolean,
    default: false,
  },
  showIcon: {
    type: Boolean,
    default: false,
  },
  submitCallback: {
    type: Function,
    default: () => {},
  },
  productId: {
    type: String,
    default: null,
  },
})

// METHODS
function openLoginModal(step) {
  events$.emit(EVENT_LOGIN.OPEN, {
    channel: props.channel,
    step,
    hideNotification: props.hideNotification,
    submitCallback: props.submitCallback,
    id: props.productId,
  })
}
</script>

<style name="mobile" scoped>
.prompt {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.prompt__title {
  color: var(--c-primary-color-3);

  .prompt__icon {
    padding-right: 8px;
  }
}

.prompt__title:hover,
.prompt__title:hover .prompt__icon {
  color: var(--c-primary-color-2);
  transition: 0.5s;
}
</style>

<style name="desktop" scoped>
@media (--md) {
  .prompt {
    flex-direction: row;
    align-items: center;
    gap: 16px;
  }
}
</style>
